// Invoice.jsx
import React from 'react';
import './TaxInvoice.css'; // Import the CSS styles
import logo from "../../Images/company-logo.png";
import TaxInvoices from './TaxInvoices';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

const TaxInvoice = () => {
  const { id } = useParams(); // Get the ID from the route parameters
  const [invoiceData, setInvoiceData] = useState(null);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Extract only the YYYY-MM-DD part
  };

  const numberToWords = (num) => {
    const a = [
      '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 
      'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 
      'Seventeen', 'Eighteen', 'Nineteen'
    ];
    const b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    const scales = ['', 'Thousand', 'Million', 'Billion'];
  
    const numToWordsHelper = (n) => {
      if (n < 20) return a[n];
      if (n < 100) return `${b[Math.floor(n / 10)]} ${a[n % 10]}`.trim();
      if (n < 1000) return `${a[Math.floor(n / 100)]} Hundred ${numToWordsHelper(n % 100)}`.trim();
      for (let i = 0; i < scales.length; i++) {
        const unit = Math.pow(1000, i + 1);
        if (n < unit) return `${numToWordsHelper(Math.floor(n / Math.pow(1000, i)))} ${scales[i]} ${numToWordsHelper(n % Math.pow(1000, i))}`.trim();
      }
    };
  
    return num === 0 ? 'Zero' : numToWordsHelper(num);
  };
  




  // Fetch invoice data from the API using the ID from params
  useEffect(() => {
    fetch(`http://localhost:3009/api/v1/externalinvoice/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setInvoiceData(data.data); // Set the fetched data to state
        }
      })
      .catch((error) => console.error('Error fetching invoice data:', error));
  }, [id]);

  if (!invoiceData) {
    return <p>Loading...</p>; // Show loading message until data loads
  }
  const invoiceAmountInWords = numberToWords(invoiceData.invoiceAmount);

  return (
    <>
    <div className="invoice-container">
      <div className="invoice-header">
        <img src={logo} alt="Company Logo" className="company-logo" />
        <p className="invoice-subtitle">Tax Invoice</p>
        
      </div>
    
      <p className="invoice-original">ORIGINAL FOR RECIPIENT</p>
      <hr style={{borderColor: "black", width: "48%",marginLeft:"393px"}}/>
      <p style={{marginLeft:"393px",fontSize:"12px"}}>Please quote with all payments and quires </p>
      <div className="company-info">
        <p className='company-address'>
          Siemens Industry Software (India)Pvt Ltd, Panchsheel Business Park, 
          Survey No:2 Building C, 7th Floor, Balewadi, Pune 411045, 
         
        </p>
      </div>

      <div className="billing-info">
        <div className="billing-left">
          <p><strong>Attn: {invoiceData.customerName}</strong></p>
          <p>{invoiceData.customerAddress}</p>
      
          <p>GST: {invoiceData.customerGSTNo}</p>
        </div>

        <div className="billing-right">
          <p><strong>Invoice No: {invoiceData.invoiceNo}</strong></p>
          <p><strong>Date:{formatDate(invoiceData.date)}</strong></p>
          <p><strong>Customer No:{invoiceData.customerNo}</strong></p>
          <hr style={{borderColor: "#4F4F4F", width: "134%"}}/>
          <p>Name : {invoiceData.customerName}</p>
          <p>Department : {invoiceData.customerDepartment}</p>
          <p>Telephone : {invoiceData.customerTelephone}</p>
          <p>Email : {invoiceData.customerEmail}</p>
          <p>LSDA Number: {invoiceData.customerLSDANo}</p>
          <p>Order No : {invoiceData.customerOrderNo}</p>
          <p>Contact : {invoiceData.customerContact}</p>
          <p>Your GST Number: {invoiceData.customerGSTNo}</p>
          <p>Your PAN Number : {invoiceData.customerPANNumber}</p>
        </div>
      </div>

      <div className="shipping-details">
        <div className="shipping-left">
          <p>Billing address:</p>
          <p>{invoiceData.billingAddress}</p>
          <p>PAN:{invoiceData.customerPANNumber}</p>
          <p>GST: {invoiceData.customerGSTNo}</p>
        </div>

        <div className="shipping-right" style={{marginRight:"-17px"}}>
          <p>Place of Supply: {invoiceData.customerPlace}</p>
          <p>State Code: {invoiceData.customerStateCode}</p>
          <p>Whether Tax Payable under RCM: No</p>
          <p>Shipping Address:</p>
          <p>{invoiceData.shippingAddress}</p>
          </div>
      </div>

      <table className="invoice-table">
        <thead>
          <tr>
            <th>Item</th>
            <th>Designation</th>
            <th>Quantity</th>
            <th>QU</th>
            <th>Price in INR</th>
            <th>Total in INR</th>
          </tr>
        </thead>
        <tbody>
        {invoiceData.items.map((item) => (
              <tr key={item.id}>
                <td>{item.itemCode}</td>
                <td>{item.itemDesignation}</td>
                <td>{item.itemQuantity}</td>
                <td>USR</td>
                <td>{item.itemPrice}</td>
                <td>{item.itemTotal}</td>
              </tr>
            ))}
        </tbody>
      </table>
       <hr style={{borderColor: "#4F4F4F", width: "100%"}}/>
      <div className="invoice-summary">
        <p style={{fontSize:"12px ",fontWeight:"bold",marginLeft:"80px",paddingTop:"10px",textAlign:"start"}}>Invoice Amount : {invoiceData.invoiceAmount}</p>
        <p style={{fontSize:"12px ",marginLeft:"39px",paddingTop:"10px",textAlign:"start"}}>Tax Code: JOIG IN: Integrated GST 18.00 %</p>
       
        <p style={{fontSize:"12px" ,fontWeight:"bold",marginRight:"20px"}}>GSTTotal : {invoiceData.gstTotal}</p>
        <p style={{fontSize:"12px ",fontWeight:"bold",marginLeft:"90px",paddingTop:"10px",textAlign:"start"}}> {invoiceAmountInWords}.</p>
      </div>
      <hr style={{borderColor: "#4F4F4F", width: "100%"}}/>

      <div className="invoice-disclaimer">
        <p>
          These items are controlled by the U.S. Government (when labeled with 
          "ECCN" unequal "N") and authorized for export only to the country of 
          ultimate destination for use by the ultimate consignee or end-user(s) 
          herein identified. They may not be resold, transferred, or otherwise 
          disposed of, to any other country or to any person other than the 
          authorized ultimate consignee or end-user(s), either in their original 
          form or after being incorporated into other items.
        </p>
      </div>
      <hr style={{borderColor: "#4F4F4F", width: "100%"}}/>

      <div className="invoice-footer">
        <p>Siemens Industry Software (India) Pvt Ltd</p>
        <p>Panchsheel Business Park, Survey No: 2, 7th Floor, Balewadi Pune 411045, Maharashtra, India</p>
        <p>GST Number: 27AABCM6730K1ZW</p>
      </div>
    </div>
    <TaxInvoices  />
    </>
  );
};

export default TaxInvoice;
