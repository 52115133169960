import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../utils/toast";
import "./CreateInvoice.css";

const CreateInvoice = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    invoicenumber: "",
    date: "",
    venderAddress: "",
    venderName: "",
    venderGstNumber: "",
    totalAmount: "",
    bankAccountNumber: "",
    bankName: "",
    bankIfscCode: "",
    accountType: "",
  });
  const [products, setProducts] = useState([
    { productcode: "", qty: "", description: "", amount: "" },
  ]);
  const [loading, setLoading] = useState(false);

  const calculateTotalAmount = () => {
    const total = products.reduce((acc, product) => {
      const amount = parseFloat(product.amount) || 0; // Convert amount to a number or 0 if it's empty
      return acc + amount;
    }, 0);
    setFormData((prevData) => ({ ...prevData, totalAmount: total }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleProductChange = (index, e) => {
    const { name, value } = e.target;
    const newProducts = [...products];
    newProducts[index][name] = value;
    setProducts(newProducts);
    calculateTotalAmount(); // Recalculate total amount
  };

  const handleAddProduct = () => {
    setProducts([
      ...products,
      { productcode: "", qty: "", description: "", amount: "" },
    ]);
    calculateTotalAmount(); // Recalculate total amount
  };

  const handleRemoveProduct = (index) => {
    const newProducts = products.filter((_, i) => i !== index);
    setProducts(newProducts);
    calculateTotalAmount(); // Recalculate total amount
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const invoiceData = { ...formData, items: products };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}invoice/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(invoiceData),
        }
      );
      setLoading(false);
      if (response.ok) {
        Toast.fire({ icon: "success", title: "Invoice successfully added!" });
        navigate("/invoices");
      } else {
        const data = await response.json();
        Toast.fire({ icon: "error", title: data.data });
      }
    } catch (error) {
      setLoading(false);
      Toast.fire({ icon: "error", title: "An error occurred!" });
    }
  };

  return (
    <div className="invoice-form-container">
      {loading && <div className="loader">Loading...</div>}
      <form onSubmit={handleSubmit} className="invoice-form">
        <h2 style={{ fontWeight: "bold" }}>Invoice Information</h2>
        {/* Invoice Fields */}
        <div className="form-row">
          <label>Invoice Number</label>
          <input
            required
            type="text"
            name="invoicenumber"
            value={formData.invoicenumber}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Date</label>
          <input
            required
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Vendor Name</label>
          <input
            required
            type="text"
            name="venderName"
            value={formData.venderName}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Vendor Address</label>
          <input
            required
            type="text"
            name="venderAddress"
            value={formData.venderAddress}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Vendor GST Number</label>
          <input
            required
            type="text"
            name="venderGstNumber"
            value={formData.venderGstNumber}
            onChange={handleChange}
          />
        </div>

        {/* Product Fields */}
        <h3>Products</h3>
        {products.map((product, index) => (
          <div key={index} className="product-row">
            <div>
              <label>Product Code</label>
              <input
                required
                type="text"
                name="productcode"
                value={product.productcode}
                onChange={(e) => handleProductChange(index, e)}
              />
            </div>
            <div>
              <label>Quantity</label>
              <input
                required
                type="number"
                name="qty"
                value={product.qty}
                onChange={(e) => handleProductChange(index, e)}
              />
            </div>
            <div>
              <label>Description</label>
              <input
                required
                type="text"
                name="description"
                value={product.description}
                onChange={(e) => handleProductChange(index, e)}
              />
            </div>
            <div>
              <label>Amount</label>
              <input
                required
                type="text"
                name="amount"
                value={product.amount}
                onChange={(e) => handleProductChange(index, e)}
              />
            </div>
            <button type="button" onClick={() => handleRemoveProduct(index)}>
              Remove
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddProduct}>
          Add Another Product
        </button>

        {/* Rest of Invoice Fields */}
        <div className="form-row">
          <label>Total Amount</label>
          <input
            required
            type="text"
            name="totalAmount"
            value={formData.totalAmount}
            readOnly // Make the input read-only
          />
        </div>
        <div className="form-row">
          <label>Bank Account Number</label>
          <input
            required
            type="text"
            name="bankAccountNumber"
            value={formData.bankAccountNumber}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Bank Name</label>
          <input
            required
            type="text"
            name="bankName"
            value={formData.bankName}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Bank IFSC Code</label>
          <input
            required
            type="text"
            name="bankIfscCode"
            value={formData.bankIfscCode}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Account Type</label>
          <input
            required
            type="text"
            name="accountType"
            value={formData.accountType}
            onChange={handleChange}
          />
        </div>

        <button className="invoice-form-button" type="submit">
          Create Invoice
        </button>
      </form>
    </div>
  );
};

export default CreateInvoice;
