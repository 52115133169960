import DashboardCard from "./DashboardCard";
import './index.css'

import payroll from '../../Images/payroll-image.png'
import invoiceImage from '../../Images/invoice-image.png'
import purchaseOrderImage from '../../Images/purchase-order-image.png'

const Dashboard = ()=>{
    return (
      <div className="dashboard">
        <DashboardCard
          title="Pay Slip"
          imgSrc={payroll}
          className="dashboard-card-1"
        />
        <DashboardCard
          title="Invoice"
          imgSrc={invoiceImage}
          className="dashboard-card-2"
        />
        <DashboardCard
          title="Purchase Order"
          imgSrc={purchaseOrderImage}
          className="dashboard-card-3"
        />
        
      </div>
    );
}
export default Dashboard