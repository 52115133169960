
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ConfirmModal from "./ConfirmModal";
import "./ExEmployees.css";
import Toast from  "../../utils/toast";

const ExEmployees = () => {
  const [members, setMembers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
 // Loader state for file upload
  const membersPerPage = 10;

  const { id } = useParams();

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}employee/inactive`
        );
    
        if (response.ok) {
          const data = await response.json();
          // Updated the condition to match the expected response structure
          if (data && Array.isArray(data.employees)) {
            setMembers(data.employees);
          } else {
            console.error("Unexpected response structure:", data);
           
          }
        } else {
          const errorData = await response.json();
          console.error("Error fetching members:", errorData.message);
          
        }
      } catch (error) {
        console.error("Error fetching members:", error);
     
      } finally {
        setLoading(false);
      }
    };
    

    fetchMembers();
  }, [id, searchTerm]);

  const indexOfLastMember = currentPage * membersPerPage;
  const indexOfFirstMember = indexOfLastMember - membersPerPage;
  const currentMembers = members.slice(indexOfFirstMember, indexOfLastMember);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(members.length / membersPerPage);

  const handleDeleteClick = (id) => {
    setMemberToDelete(id);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}employee/${memberToDelete}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        setMembers(members.filter((member) => member.id !== memberToDelete));
        setShowModal(false);
      } else {
        console.error("Error deleting member:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting member:", error);
    }
  };

  // Function to handle file upload
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}employee/upload`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        setMembers([...members, ...result.employees]);
        alert("Employees imported successfully");
      } else {
        const error = await response.json();
        console.error("Error uploading file:", error.message);
        alert("Failed to upload employees data.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file.");
    } finally {
     
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="ex-team-container">
  
      <table className="ex-team-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Employee Id</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Email</th>
            <th>Actions</th>
            <th>Create Payslip</th>
          </tr>
        </thead>
        <tbody>
          {currentMembers.map((member, index) => (
            <tr key={member.id}>
              <td>{indexOfFirstMember + index + 1}</td>
              <td>{member.employeeId}</td>
              <td>{member.name}</td>
              <td>{member.designation}</td>
              <td>{member.email}</td>
              <td className="ex-actions">
                <Link to={`/edit/${member.id}`}>
                  <button className="ex-action-button edit">
                    <i className="fas fa-edit"></i>
                  </button>
                </Link>
                <button
                  className="ex-action-button delete"
                  onClick={() => handleDeleteClick(member.id)}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
                <Link to={`/view/${member.id}`}>
                  <button
                    className="ex-action-button view"
                    onClick={() => handleView(member.id)}
                  >
                    <i className="fas fa-eye"></i>
                  </button>
                </Link>
              </td>
              <td>
                <Link to={`/payslip/${member.id}`}>
                  <button
                    className="ex-payslip-button"
                    onClick={() => handleCreatePayslip(member.id)}
                  >
                    Create Payslip
                  </button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <ConfirmModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={handleConfirmDelete}
      />
      
    </div>
  );
};

// Placeholder handler functions for actions
const handleView = (id) => {
  console.log("View details of member with ID:", id);
  // Implement view functionality here
};

const handleCreatePayslip = (id) => {
  console.log("Create payslip for member with ID:", id);
  // Implement create payslip functionality here
};

export default ExEmployees;
