import { useNavigate } from "react-router-dom";

import { IoLogOut } from "react-icons/io5";

import Cookies from "js-cookie";
import "./index.css";

const AdminNavbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("jwt");
    navigate("/login");
  };
  return (
    <nav className="navbar-main-nav-container">
      <div className="navbar-mini-nav-container">
        {/* <div className="employee-navbar-username-main-container">
          <p className="employee-navbar-text">Valcan</p>
          <img src={logo} alt="profile" className="user-info-navbar-logo" />
        </div> */}
        <button className="admin-logout-button m-0" onClick={handleLogout}>
          <IoLogOut /> Logout
        </button>
      </div>
    </nav>
  );
};

export default AdminNavbar;
