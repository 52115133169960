import React, { useState } from 'react';
import './PurchaseForm.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';


const PurchaseForm = () => {
  const [invoiceData, setInvoiceData] = useState({
    vendorName: '',
    vendorAddress: '',
    vendorGstin: '',
    vendorCIN: '',
    vendorCode: '',
    poNo: '',
    poDate: '',
    items: [{ description: '', qty: '', unitPrice: '', totalAmount: 0 }],
    subTotal: 0,
    gstAmount: 0,
    grandTotal: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const updatedItems = [...invoiceData.items];
    updatedItems[index][name] = value;
    setInvoiceData((prev) => ({ ...prev, items: updatedItems }));
  };

  const addItem = () => {
    setInvoiceData((prev) => ({
      ...prev,
      items: [...prev.items, { description: '', qty: '', unitPrice: '', totalAmount: 0 }],
    }));
  };

  const removeItem = (index) => {
    const updatedItems = invoiceData.items.filter((_, i) => i !== index);
    setInvoiceData((prev) => ({ ...prev, items: updatedItems }));
  };

  const calculateTotal = (index) => {
    const updatedItems = [...invoiceData.items];
    const item = updatedItems[index];
    const total = Number(item.qty) * Number(item.unitPrice);
    updatedItems[index].totalAmount = total;

    const subTotal = updatedItems.reduce((sum, item) => sum + item.totalAmount, 0);
    const gstAmount = subTotal * 0.18;
    const grandTotal = subTotal + gstAmount;

    setInvoiceData((prev) => ({
      ...prev,
      items: updatedItems,
      subTotal,
      gstAmount,
      grandTotal,
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch('http://localhost:3009/api/v1/purchaseorder/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          vendorName: invoiceData.vendorName,
          vendorAddress: invoiceData.vendorAddress,
          vendorGstin: invoiceData.vendorGstin,
          vendorCin: invoiceData.vendorCIN,
          vendorCode: invoiceData.vendorCode,
          poNumber: invoiceData.poNo,
          poDate: invoiceData.poDate,
          subTotal: invoiceData.subTotal,
          gstAmount: invoiceData.gstAmount,
          grandTotal: invoiceData.grandTotal,
          products: invoiceData.items.map((item) => ({
            description: item.description,
            quantity: Number(item.qty),
            unitPrice: Number(item.unitPrice),
            totalAmount: item.totalAmount,
          })),
        }),
      });
      if (response.ok) {
        alert('Purchase order submitted successfully!');
        // Reset form if needed
        setInvoiceData({
          vendorName: '',
          vendorAddress: '',
          vendorGstin: '',
          vendorCIN: '',
          vendorCode: '',
          poNo: '',
          poDate: '',
          items: [{ description: '', qty: '', unitPrice: '', totalAmount: 0 }],
          subTotal: 0,
          gstAmount: 0,
          grandTotal: 0,
        });
      } else {
        alert('Failed to submit purchase order.');
      }
    } catch (error) {
      console.error('Error submitting purchase order:', error);
    }
  };

  return (
    <div className="purchase-form-container">
      <h1 className='poform-titles'>Purchase Order Form</h1>
      <div className="form-grid">
        <div className="form-field">
          <label>Vendor Name:</label>
          <input
            type="text"
            name="vendorName"
            value={invoiceData.vendorName}
            onChange={handleChange}
          />
        </div>
        <div className="form-field">
          <label>Vendor Address:</label>
          <input
            type="text"
            name="vendorAddress"
            value={invoiceData.vendorAddress}
            onChange={handleChange}
          />
        </div>
        <div className="form-field">
          <label>Vendor GSTIN:</label>
          <input
            type="text"
            name="vendorGstin"
            value={invoiceData.vendorGstin}
            onChange={handleChange}
          />
        </div>
        <div className="form-field">
          <label>Vendor CIN:</label>
          <input
            type="text"
            name="vendorCIN"
            value={invoiceData.vendorCIN}
            onChange={handleChange}
          />
        </div>
        <div className="form-field">
          <label>Vendor Code:</label>
          <input
            type="text"
            name="vendorCode"
            value={invoiceData.vendorCode}
            onChange={handleChange}
          />
        </div>
        <div className="form-field">
          <label>PO Number:</label>
          <input
            type="text"
            name="poNo"
            value={invoiceData.poNo}
            onChange={handleChange}
          />
        </div>
        <div className="form-field">
          <label>PO Date:</label>
          <input
            type="date"
            name="poDate"
            value={invoiceData.poDate}
            onChange={handleChange}
          />
        </div>
      </div>

      <h2 className='poform-h2'> Products</h2>
      {invoiceData.items.map((item, index) => (
        <div key={index} className="line-item">
          <div className="form-field">
            <label htmlFor={`description-${index}`}>Description</label>
            <input
              type="text"
              id={`description-${index}`}
              name="description"
              placeholder="Description"
              value={item.description}
              onChange={(e) => handleItemChange(index, e)}
            />
          </div>
          <div className="form-field">
            <label htmlFor={`qty-${index}`}>Qty</label>
            <input
              type="number"
              id={`qty-${index}`}
              name="qty"
              placeholder="Qty"
              value={item.qty}
              onChange={(e) => handleItemChange(index, e)}
              onBlur={() => calculateTotal(index)}
            />
          </div>
          <div className="form-field">
            <label htmlFor={`unitPrice-${index}`}>Unit Price</label>
            <input
              type="number"
              id={`unitPrice-${index}`}
              name="unitPrice"
              placeholder="Unit Price"
              value={item.unitPrice}
              onChange={(e) => handleItemChange(index, e)}
              onBlur={() => calculateTotal(index)}
            />
          </div>
          <div className="form-field">
            <label htmlFor={`totalAmount-${index}`}>Total</label>
            <input
              type="number"
              id={`totalAmount-${index}`}
              name="totalAmount"
              placeholder="Total"
              value={item.totalAmount}
              readOnly
            />
          </div>
          <div className='poform-buttons'>
          <button onClick={addItem} className='add-btn'>
        <FontAwesomeIcon icon={faPlus} /> 
      </button>
          <button  className="remove-btn" onClick={() => removeItem(index)}>
            <FontAwesomeIcon icon={faMinus} /> 
          </button>
          </div>
        </div>
      ))}
  

      <div className="totals">
        <div className="form-field">
          <label>Sub Total:</label>
          <input type="number" value={invoiceData.subTotal.toFixed(2)} readOnly />
        </div>
        <div className="form-field">
          <label>GST (18%):</label>
          <input type="number" value={invoiceData.gstAmount.toFixed(2)} readOnly />
        </div>
        <div className="form-field">
          <label>Grand Total:</label>
          <input type="number" value={invoiceData.grandTotal.toFixed(2)} readOnly />
        </div>
      </div>
      <button className="submit-btn" onClick={handleSubmit}>Submit Order</button>
    </div>
  );
};

export default PurchaseForm;
