// src/components/PurchaseOrder.js

import React from 'react';
import './PurchaseOrders.css';

const PurchaseOrders = () => {
  return (
    <div className="purchase-order">
      <h2 className="title">IMPORTANT INSTRUCTIONS</h2>
      <ol>
        <li>All Invoices must indicate PO number, Code No. Item description & Vendor Code must appear on all challans & invoices.</li>
        <li>Materials to reach before 3PM.</li>
        <li>This Purchase Order shall be acknowledged within 10 days of the receipt.</li>
        <li>All claims of Excise Duty, Insurance, Freight, P/F charges &/or delivery charges listing charges etc. wherever payable by us must be supported with relevant documentary evidence.</li>
      </ol>
      
      <h2 className="title">DELIVERY:-</h2>
      <p>On Delivery, period is the essence of the order and must be strictly adhered to, we reserve the right to cancel in the part or in full if goods are not delivered within the specified time. We also reserve the right to refuse acceptance of goods supplied ahead of delivery schedule or after delivery schedule. In the event of your failure to supply goods in time we reserve the right to purchase the same from other sources.</p>

      <h2 className="title">PRICES:-</h2>
      <p>The prices mentioned in the order are fixed and will not change unless agreed by us in writing.</p>

      <h2 className="title">PACKING:-</h2>
      <p>
        To Material must be supplied in duly packed as specified in the P.O. However the Vendor should be ensure that the goods’ reach intact in the good’s to our the recipient Store, in good & dry supplies. It is the responsibility of the Vendor to choose the type of packing material to carry the supplied without any loss in qty/supplies.
        <ol>
          <li>Each Carton / Packet should have passed packing slip pasted in English outside the box including Supplier’s Name Part description & P.O. NO.</li>
          <li>Material prone to leakage, evaporation moisture etc. should be packed in a way to avoid any loss in weight or quality.</li>
        </ol>
      </p>

      <h2 className="title">VENDOR INSPECTION REPORT:-</h2>
      <p>It shall be mandatory that Vendor inspection report is sent along with dispatch documents without which the supplies are likely to be rejected.</p>

      <h2 className="title">INSPECTION / REJECTION:-</h2>
      <p>
        All Supplies shall be subject to verification / testing by our as per details in P.O. accompany Inspection Standards Decision of our Inspector shall be final and binding on the supplier.
        <ol>
          <li>Rejected and will be subject to return at the risk and cost of supplier.</li>
          <li>May be immediately lifted by supplier or our representatives and customers from his works whenever desired.</li>
          <li>Inspection of raw materials used by you in our supplies / material is found unsuitable during actual use.</li>
          <li>Supplies rejected by our Inspectors may be lifted by vendor immediately on hearing of our Inspection Report, even though if not lifted the same will be dispatched from our Store/SAFE, and shall be recovered from the supplier.</li>
        </ol>
      </p>

      <h2 className="title">MANUFACTURER'S MARK:-</h2>
      <p>All finished materials should be stamped to enable easy identification.</p>

      <h2 className="title">ELIMINATION OF REJECTIONS:-</h2>
      <p>
        It is a view that your Self certification Scheme for Quality for being in vogue. Vendors are requested to 100% quality check at their works to eliminate any rejection and eliminate any rejection at our works by giving full justification through target of T.Q.E. analysis.
        <ol>
          <li>Vendors are advised to submit to the earlier supplies lists to join the Rejection Stages as vendor’s stocks/ finished stocks in our process stores. Immediate action and quality checks must be kept in the Rejection Stages as vendor’s stocks/ finished stocks in our process stores. Immediate action and quality checks must be taken to avoid reoccurrence of rejection in supplies from such stocks.</li>
        </ol>
      </p>

      <h2 className="title">MOULDS & DIES SAMPLES, DRAWING AND SKETCHES:-</h2>
      <p>
        All documents pertaining to the suppliers shall be fully indemnified by the company by the supplier shall always remain the property and in the custody of the company and shall be preserved by the company by the supplier. The supplier will not pass on any of the documents mentioned in the P.O. or related there to be kept in strict confidence. The supplier will have to obtain prior approval from the Company before making any use of such documents for any purpose other than the fulfillment of the P.O.
      </p>

      <h2 className="title">VENDOR RATING:-</h2>
      <p>Strictnessness apply rejections affect your vendor rating. Thus, utmost attention must be accorded to the same.</p>
    </div>
  );
};

export default PurchaseOrders;
