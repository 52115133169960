import React, { useState, useEffect } from "react";
import "./ExternalInvoiceForm.css";

const ExternalInvoiceForm = () => {
  const [formData, setFormData] = useState({
    customerName: "",
    customerAddress: "",
    customerGSTNo: "",
    invoiceNo: "",
    date: "",
    customerNo: "",
    billingAddress: "",
    customerDepartment: "",
    customerTelephone: "",
    customerEmail: "",
    customerLSDANO: "",
    customerOrderNo: "",
    customerContact: "",
    customerPANNumber: "",
    customerPlace: "",
    customerStateCode: "",
    shippingAddress: "",
    gstTotal: 0,
    invoiceAmount: 0,
    accountNumber: "",
    bankName: "",
    ifscCode: "",
    microNum: "",
    iban: "",
  });

  const [items, setItems] = useState([
    {
      itemCode: "",
      itemDesignation: "",
      itemQuantity: 0,
      itemPrice: 0,
      itemTotal: 0,
    },
  ]);

  const [loading, setLoading] = useState(false);

  // Helper function to calculate the total amount from all items
  const calculateTotals = () => {
    const totalProductAmount = items.reduce(
      (sum, item) => sum + parseFloat(item.itemTotal || 0),
      0
    );

    const gstTotal = (totalProductAmount * 18) / 100; // 18% GST
    const invoiceAmount = totalProductAmount + gstTotal;

    setFormData((prevData) => ({
      ...prevData,
      gstTotal: gstTotal.toFixed(2),
      invoiceAmount: invoiceAmount.toFixed(2),
    }));
  };

  // Update item total when quantity or price changes
  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const updatedItems = [...items];
    updatedItems[index][name] = value;

    if (name === "itemQuantity" || name === "itemPrice") {
      const quantity = parseFloat(updatedItems[index].itemQuantity) || 0;
      const price = parseFloat(updatedItems[index].itemPrice) || 0;
      updatedItems[index].itemTotal = (quantity * price).toFixed(2);
    }

    setItems(updatedItems);
  };

  // Calculate totals whenever items are added/removed or modified
  useEffect(() => {
    calculateTotals();
  }, [items]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const addItem = () => {
    setItems([
      ...items,
      { itemCode: "", itemDesignation: "", itemQuantity: 0, itemPrice: 0, itemTotal: 0 },
    ]);
  };

  const removeItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const invoiceData = { ...formData, items };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}externalinvoice`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(invoiceData),
        }
      );

      if (response.ok) {
        alert("Invoice submitted successfully!");
        setFormData({
          customerName: "",
          customerAddress: "",
          customerGSTNo: "",
          invoiceNo: "",
          date: "",
          customerNo: "",
          billingAddress: "",
          customerDepartment: "",
          customerTelephone: "",
          customerEmail: "",
          customerLSDANO: "",
          customerOrderNo: "",
          customerContact: "",
          customerPANNumber: "",
          customerPlace: "",
          customerStateCode: "",
          shippingAddress: "",
          gstTotal: 0,
          invoiceAmount: 0,
          accountNumber: "",
          bankName: "",
          ifscCode: "",
          microNum: "",
          iban: "",
        });
        setItems([{ itemCode: "", itemDesignation: "", itemQuantity: 0, itemPrice: 0, itemTotal: 0 }]);
      } else {
        alert("Failed to submit the invoice. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting invoice:", error);
      alert("An error occurred while submitting the invoice.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="external-invoice-container">
      <h2 className="external-invoice-title">External Invoice Form</h2>
      <form onSubmit={handleSubmit} className="external-invoice-form">
        {Object.keys(formData).map((key) => (
          <div className="external-invoice-form-group" key={key}>
            <label className="external-invoice-label">
              {key.replace(/([A-Z])/g, " $1").trim()}
            </label>
            <input
              type={key === "date" ? "date" : "text"}
              name={key}
              value={formData[key]}
              onChange={handleFormChange}
              className="external-invoice-input"
            />
          </div>
        ))}

        <h3>Items</h3>
        {items.map((item, index) => (
          <div key={index} className="external-invoice-item">
            <h4>Item {index + 1}</h4>
            <div className="external-invoice-form-group">
              <label className="external-invoice-label">Item Code</label>
              <input
                type="text"
                name="itemCode"
                value={item.itemCode}
                onChange={(e) => handleItemChange(index, e)}
                className="external-invoice-input"
              />
            </div>
            <div className="external-invoice-form-group">
              <label className="external-invoice-label">Item Designation</label>
              <input
                type="text"
                name="itemDesignation"
                value={item.itemDesignation}
                onChange={(e) => handleItemChange(index, e)}
                className="external-invoice-input"
              />
            </div>
            <div className="external-invoice-form-group">
              <label className="external-invoice-label">Item Quantity</label>
              <input
                type="number"
                name="itemQuantity"
                value={item.itemQuantity}
                onChange={(e) => handleItemChange(index, e)}
                className="external-invoice-input"
              />
            </div>
            <div className="external-invoice-form-group">
              <label className="external-invoice-label">Item Price</label>
              <input
                type="number"
                name="itemPrice"
                value={item.itemPrice}
                onChange={(e) => handleItemChange(index, e)}
                className="external-invoice-input"
              />
            </div>
            <div className="external-invoice-form-group">
              <label className="external-invoice-label">Item Total</label>
              <input
                type="number"
                name="itemTotal"
                value={item.itemTotal}
                readOnly
                className="external-invoice-input"
              />
            </div>
            <button
              type="button"
              onClick={() => removeItem(index)}
              className="external-invoice-remove-button"
            >
              Remove Item
            </button>
          </div>
        ))}

        <button
          type="button"
          onClick={addItem}
          className="external-invoice-add-button"
        >
          Add Item
        </button>

        <button
          type="submit"
          className="external-invoice-submit-button"
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit Invoice"}
        </button>
      </form>
    </div>
  );
};

export default ExternalInvoiceForm;
