import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams for accessing URL params
import './PurchaseOrder.css';
import logo from "../../Images/company-logo.png";
import PurchaseOrders from "./PurchaseOrders";

const PurchaseOrder = () => {
  const { id } = useParams(); // Get the ID from the URL parameters
  const [purchaseOrder, setPurchaseOrder] = useState(null); // State to hold the purchase order data

  useEffect(() => {
    const fetchPurchaseOrder = async () => {
      try {
        const response = await fetch(`http://localhost:3009/api/v1/purchaseorder/${id}`);
        const result = await response.json();
        
        if (result.success) {
          setPurchaseOrder(result.data); // Set the fetched data in state
        }
      } catch (error) {
        console.error("Error fetching purchase order:", error);
      }
    };

    fetchPurchaseOrder(); // Call the fetch function
  }, [id]); // Dependency array includes id to refetch if it changes

  // Render loading or error message if data is not yet fetched
  if (!purchaseOrder) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div id="purchase-order">
        <div className="po-container">
          <header className="header">
            <img src={logo} alt="Company Logo" className="logo" />
            <div className="header-text">
              <h1>LABYRINTH GLOBAL SOLUTIONS INDIA PRIVATE LIMITED</h1>
            </div>
          </header>

          <div className="po-info">
            <table className="po-info-table">
              <tbody>
                <tr>
                  <td className="info-block">
                    <h3>Detail of Receiver (Billed to):</h3>
                    <p>LABYRINTH GLOBAL SOLUTIONS INDIA PRIVATE LIMITED</p>
                    <p>H.No. 16-2-684/1, Chanchalguda, Malakpet</p>
                    <p>Charminar, Hyderabad, Telangana, 500002</p>
                    <p>State Code: 36 - TELANGANA</p>
                    <p>GSTIN: 36AAFCL4792K1ZG</p>
                    <p>CIN: U265767SDB87PCTC714623</p>
                  </td>
                  <td className="info-block">
                    <h3>Deliver to:</h3>
                    <p>LABYRINTH GLOBAL SOLUTIONS INDIA PRIVATE LIMITED</p>
                    <p>{purchaseOrder.vendorAddress}</p>
                    <p>Charminar, Hyderabad, Telangana, 500002</p>
                    <p>{purchaseOrder.vendorCode}</p>
                    <p>GSTIN: {purchaseOrder.vendorGstin}</p>
                    <p>CIN:{purchaseOrder.vendorCin}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="vendor-container">
            <div className="vendor-details">
              <h3>Vendor Name & Address</h3>
              <p>{purchaseOrder.vendorName}</p>
              <p>{purchaseOrder.vendorAddress}</p>
              <p>Telangana, India</p>
            </div>
            <div className="vendor-info">
              <p className="info-line"><span className="label">Vendor Code:</span> <span>{purchaseOrder.vendorCode}</span></p>
              <p className="info-line"><span className="label">GSTIN:</span> <span>{purchaseOrder.vendorGstin}</span></p>
              <p className="info-line"><span className="label">CIN:</span> <span>{purchaseOrder.vendorCin}</span></p>
              <p className="info-line"><span className="label">PO No:</span> <span>{purchaseOrder.poNumber}</span></p>
              <p className="info-line"><span className="label">PO Date:</span> <span>{purchaseOrder.poDate}</span></p>
            </div>
          </div>

          <div className="po-table">
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Description</th>
                  <th>Qty</th>
                  <th>Unit Price</th>
                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {purchaseOrder.products.map((product, index) => (
                  <tr key={product.id}>
                    <td>{index + 1}</td>
                    <td>{product.description}</td>
                    <td>{product.quantity}</td>
                    <td>{product.unitPrice}</td>
                    <td>{product.totalAmount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="terms-conditions">
            <table>
              <tbody>
                <tr>
                  <td className="amount-label">SUB TOTAL</td>
                  <td className="amount-value">{purchaseOrder.subTotal}</td>
                </tr>
                <tr>
                  <td className="amount-label">GST AMOUNT</td>
                  <td className="amount-value">{purchaseOrder.gstAmount}</td>
                </tr>
                <tr>
                  <td className="amount-label">GRAND TOTAL</td>
                  <td className="amount-value">{purchaseOrder.grandTotal}</td>
                </tr>
              </tbody>
            </table>
            <div className="additional-info" style={{ marginTop: '10px' }}>
              <p>NOTE :</p>
              <ul>
                <li>1. Delivery schedule will be released separately</li>
                <li>2. Material would be dispatched in accordance with the monthly schedule</li>
              </ul>
            </div>

            <div className="terms" style={{ marginTop: '10px' }}>
              <h3>Terms & Conditions:</h3>
              <p>1. Immediate due date</p>
              <p>2. Payment Terms: As soon as possible</p>
            </div>
            <div className="remarks">
              <p>REMARKS</p>
            </div>
            <div className="grand-total">
              <p>GRAND TOTAL (IN WORDS):</p>
            </div>
            <footer className="footer">
              <p>for LABYRINTH GLOBAL SOLUTIONS INDIA PRIVATE LIMITED</p>
              <h4>(Authorised Signatory)</h4>
            </footer>
          </div>
        </div>
      </div>
      <div id="purchase-orders" className="page-break">
        <PurchaseOrders />
      </div>
    </>
  );
}

export default PurchaseOrder;
