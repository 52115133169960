import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./EditInvoice.css";

const EditInvoice = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  const [formData, setFormData] = useState({
    invoicenumber: "",
    date: "",
    venderAddress: "",
    venderName: "",
    venderGstNumber: "",
    totalAmount: 0,
    bankAccountNumber: "",
    bankName: "",
    bankIfscCode: "",
    accountType: "",
    items: [],
  });

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}invoice/${id}`,
          { method: "GET" }
        );

        if (response.ok) {
          const data = await response.json();
          const invoiceData = data.invoice;

          const formattedDate = new Date(invoiceData.date)
            .toISOString()
            .split("T")[0];

          setInvoice(invoiceData);
          setFormData({
            invoicenumber: invoiceData.invoicenumber,
            date: formattedDate,
            venderAddress: invoiceData.venderAddress,
            venderName: invoiceData.venderName,
            venderGstNumber: invoiceData.venderGstNumber,
            totalAmount: Math.round(invoiceData.totalAmount),
            bankAccountNumber: invoiceData.bankAccountNumber,
            bankName: invoiceData.bankName,
            bankIfscCode: invoiceData.bankIfscCode,
            accountType: invoiceData.accountType,
            items: invoiceData.items
              ? invoiceData.items.map((item) => ({
                  ...item,
                  amount: Math.round(item.amount),
                }))
              : [],
          });
        } else {
          console.error("Error fetching invoice:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching invoice:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [id]);

  const calculateTotalAmount = () => {
    const total = formData.items.reduce((acc, item) => {
      const amount = parseFloat(item.amount) || 0;
      return acc + amount;
    }, 0);

    setFormData((prev) => ({
      ...prev,
      totalAmount: Math.round(total * 100) / 100,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}invoice/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        navigate("/invoices");
      } else {
        console.error("Error updating invoice:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating invoice:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  // Update total amount whenever items change
  useEffect(() => {
    calculateTotalAmount();
  }, [formData.items]);

  if (loading) {
    return <div className="loader"></div>;
  }

  if (!invoice) {
    return <div>Invoice not found</div>;
  }

  return (
    <div className="edit-container">
      <h2>Edit Invoice</h2>
      <form onSubmit={handleUpdate}>
        {/* Invoice fields */}
        {Object.entries(formData).map(
          ([key, value]) =>
            key !== "items" && (
              <div className="editform-group" key={key}>
                <label>
                  {key.charAt(0).toUpperCase() +
                    key.slice(1).replace(/([A-Z])/g, " ")}
                </label>
                <input
                  type={key === "date" ? "date" : "text"}
                  name={key}
                  value={value}
                  onChange={handleChange}
                  className="invoice-input"
                />
              </div>
            )
        )}

        {/* Display total amount */}
        <div className="editform-group">
          <label>Total Amount</label>
          <input
            type="text"
            name="totalAmount"
            value={formData.totalAmount}
            readOnly
            className="invoice-input"
          />
        </div>

        <h3>Invoice Items</h3>
        {formData.items.map((item, index) => (
          <div key={index} className="product-row">
            <div className="product-input">
              <label>Product Code</label>
              <input
                type="text"
                value={item.productcode}
                onChange={(e) => {
                  const newItems = [...formData.items];
                  newItems[index].productcode = e.target.value;
                  setFormData((prev) => ({
                    ...prev,
                    items: newItems,
                  }));
                }}
              />
            </div>
            <div className="product-input">
              <label>Quantity</label>
              <input
                type="number"
                value={item.qty}
                onChange={(e) => {
                  const newItems = [...formData.items];
                  newItems[index].qty = parseInt(e.target.value, 10) || 0;
                  setFormData((prev) => ({
                    ...prev,
                    items: newItems,
                  }));
                }}
              />
            </div>
            <div className="product-input">
              <label>Description</label>
              <input
                type="text"
                value={item.description}
                onChange={(e) => {
                  const newItems = [...formData.items];
                  newItems[index].description = e.target.value;
                  setFormData((prev) => ({
                    ...prev,
                    items: newItems,
                  }));
                }}
              />
            </div>
            <div className="product-input">
              <label>Amount</label>
              <input
                type="text"
                value={item.amount}
                onChange={(e) => {
                  const newItems = [...formData.items];
                  newItems[index].amount = parseFloat(e.target.value) || 0;
                  setFormData((prev) => ({
                    ...prev,
                    items: newItems,
                  }));
                }}
              />
            </div>
          </div>
        ))}

        <button type="submit" className="update-button" disabled={isUpdating}>
          {isUpdating ? "Updating..." : "Update"}
        </button>
      </form>
    </div>
  );
};

export default EditInvoice;
