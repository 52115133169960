import React from "react";
import ReactDOM from "react-dom/client";
import EmployeeForm from "./components/Payroll/EmployeeForm";
import "./index.css";

import reportWebVitals from "./reportWebVitals";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import CreateInvoice from "./components/Invoices/CreateInvoice";
import EditInvoice from "./components/Invoices/EditInvoice";
import Invoice from "./components/Invoices/Invoice";
import InvoicesList from "./components/Invoices/InvoicesList";
import ViewInvoice from "./components/Invoices/ViewInvoice";
import EditEmployee from "./components/Members/EditEmployee";
import ExEmployees from "./components/Members/ExEmployees";
import TeamMembers from "./components/Members/TeamMembers";
import ViewEmployee from "./components/Members/ViewEmployee";
import Payslip from "./components/Payroll/Payslip";
import PaySlips from "./components/Payroll/PaySlips";
import PayslipView from "./components/Payroll/PayslipView";
import PurchaseOrder from "./components/Po/PurchaseOrder";
import PurchaseOrders from "./components/Po/PurchaseOrders";
import Protectrouter from "./components/Protectrouter";
import AdminSidebar from "./components/Sidebar";
import Login from "./pages/Login";

import EditExternalInvoiceForm from "./components/Invoices/EditInvoiceForm";
import ExternalInvoicesList from "./components/Invoices/ExternalInvoice";
import ExternalInvoiceForm from "./components/Invoices/ExternalInvoiceForm";
import TaxInvoice from "./components/Invoices/TaxInvoice";
import TaxInvoices from "./components/Invoices/TaxInvoices";
import POLIst from "./components/Po/POLIst";
import PurchaseForm from "./components/Po/PurchaseForm";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Protectrouter>
        {" "}
        <AdminSidebar />
      </Protectrouter>
    ),
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/invoices",
        element: <InvoicesList />,
      },
      {
        path: "/externalinvoice",
        element: <ExternalInvoicesList />,
      },
      {
        path: "/users",
        element: <TeamMembers />,
      },
      {
        path: "/viewinvoice/:id",
        element: <ViewInvoice />,
      },
      {
        path: "/payslips",
        element: <PaySlips />,
      },
      {
        path: "/payslip",
        element: <TeamMembers />,
      },
      {
        path: "/po",
        element: <PurchaseOrder />,
      },
      {
        path: "/po1",
        element: <PurchaseOrders />,
      },
      {
        path: "/edit/:id",
        element: <EditEmployee />,
      },
      {
        path: "/employeeForm",
        element: <EmployeeForm />,
      },
      {
        path: "/view/:id",
        element: <ViewEmployee />,
      },
      {
        path: "/payslip/:id",
        element: <Payslip />,
      },
      {
        path: "/purchase-order/:id",
        element: <PurchaseOrder />,
      },
      {
        path: "/po1",
        element: <PurchaseOrders />,
      },
      {
        path: "/invoice/:id",
        element: <Invoice />,
      },
      {
        path: "/createInvoice",
        element: <CreateInvoice />,
      },
      {
        path: "/viewinvoice/:id",
        element: <ViewInvoice />,
      },
      {
        path: "/editInvoice/:id",
        element: <EditInvoice />,
      },
      {
        path: "/editexternalinvoice/:id",
        element: <EditExternalInvoiceForm />,
      },
      {
        path: "/viewpayslip/:id",
        element: <PayslipView />,
      },
      {
        path: "/ex-employees",
        element: <ExEmployees />,
      },

      {
        path: "/purchaseform",
        element: <PurchaseForm />,
      },
      {
        path: "/polist",
        element: <POLIst />,
      },
      {
        path: "/externalinvoiceform",
        element: <ExternalInvoiceForm />,
      },
      {
        path: "/tax/:id",
        element: <TaxInvoice />,
      },
      {
        path: "/taxes/:id",
        element: <TaxInvoices />,
      },

    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/viewinvoice/:id",
    element: <ViewInvoice />,
  },
  
  {
    path: "/externalinvoice",
    element: <ExternalInvoiceForm />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
