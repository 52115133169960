import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ExternalInvoiceForm.css";

const EditExternalInvoiceForm = () => {
  const [formData, setFormData] = useState({
    customerName: "",
    customerAddress: "",
    customerGSTNo: "",
    invoiceNo: "",
    date: "",
    customerNo: "",
    billingAddress: "",
    customerDepartment: "",
    customerTelephone: "",
    customerEmail: "",
    customerLSDANO: "",
    customerOrderNo: "",
    customerContact: "",
    customerPANNumber: "",
    customerPlace: "",
    customerStateCode: "",
    shippingAddress: "",
    gstTotal: "",
    invoiceAmount: "",
    accountNumber: "",
    bankName: "",
    ifscCode: "",
    microNum: "",
    iban: "",
  });

  const { id } = useParams();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchInvoiceData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}externalinvoice/${id}`
        );
        const data = await response.json();

        if (data.success) {
          const invoice = data.data;
          setFormData({
            customerName: invoice.customerName,
            customerAddress: invoice.customerAddress,
            customerGSTNo: invoice.customerGSTNo,
            invoiceNo: invoice.invoiceNo,
            date: invoice.date.split("T")[0], // Format date to YYYY-MM-DD
            customerNo: invoice.customerNo,
            billingAddress: invoice.billingAddress,
            customerDepartment: invoice.customerDepartment,
            customerTelephone: invoice.customerTelephone,
            customerEmail: invoice.customerEmail,
            customerLSDANO: invoice.customerLSDANO,
            customerOrderNo: invoice.customerOrderNo,
            customerContact: invoice.customerContact,
            customerPANNumber: invoice.customerPANNumber,
            customerPlace: invoice.customerPlace,
            customerStateCode: invoice.customerStateCode,
            shippingAddress: invoice.shippingAddress,
            gstTotal: invoice.gstTotal,
            invoiceAmount: invoice.invoiceAmount,
            accountNumber: invoice.accountNumber,
            bankName: invoice.bankName,
            ifscCode: invoice.ifscCode,
            microNum: invoice.microNum,
            iban: invoice.iban,
          });
          setItems(invoice.items || []);
        } else {
          alert("Failed to fetch invoice data.");
        }
      } catch (error) {
        console.error("Error fetching invoice data:", error);
        alert("An error occurred while fetching the invoice data.");
      } finally {
        setLoading(false);
      }
    };

    fetchInvoiceData();
  }, [id]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const updatedItems = [...items];
    updatedItems[index][name] = value;
    setItems(updatedItems);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const invoiceData = {
      ...formData,
      items,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}externalinvoice/${id}`,
        {
          method: "PUT", // Use PUT for updating
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(invoiceData),
        }
      );

      if (response.ok) {
        alert("Invoice updated successfully!");
      } else {
        alert("Failed to update the invoice. Please try again.");
      }
    } catch (error) {
      console.error("Error updating invoice:", error);
      alert("An error occurred while updating the invoice.");
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="external-invoice-container">
      <h2 className="external-invoice-title">Edit Invoice Form</h2>
      <form onSubmit={handleSubmit} className="external-invoice-form">
        {Object.keys(formData).map((key) => (
          <div className="external-invoice-form-group" key={key}>
            <label className="external-invoice-label">
              {key.replace(/([A-Z])/g, " $1").trim()}
            </label>
            <input
              type={key === "date" ? "date" : "text"}
              name={key}
              value={formData[key]}
              onChange={handleFormChange}
              className="external-invoice-input"
            />
          </div>
        ))}

        <h3>Items</h3>
        {items.map((item, index) => (
          <div key={index} className="external-invoice-item">
            <h4>Item {index + 1}</h4>
            <div className="external-invoice-form-group">
              <label className="external-invoice-label">Item Code</label>
              <input
                type="text"
                name="itemCode"
                value={item.itemCode}
                onChange={(e) => handleItemChange(index, e)}
                className="external-invoice-input"
              />
            </div>
            <div className="external-invoice-form-group">
              <label className="external-invoice-label">Item Designation</label>
              <input
                type="text"
                name="itemDesignation"
                value={item.itemDesignation}
                onChange={(e) => handleItemChange(index, e)}
                className="external-invoice-input"
              />
            </div>
            <div className="external-invoice-form-group">
              <label className="external-invoice-label">Item Quantity</label>
              <input
                type="number"
                name="itemQuantity"
                value={item.itemQuantity}
                onChange={(e) => handleItemChange(index, e)}
                className="external-invoice-input"
              />
            </div>
            <div className="external-invoice-form-group">
              <label className="external-invoice-label">Item Price</label>
              <input
                type="number"
                name="itemPrice"
                value={item.itemPrice}
                onChange={(e) => handleItemChange(index, e)}
                className="external-invoice-input"
              />
            </div>
            <div className="external-invoice-form-group">
              <label className="external-invoice-label">Item Total</label>
              <input
                type="number"
                name="itemTotal"
                value={item.itemTotal}
                onChange={(e) => handleItemChange(index, e)}
                className="external-invoice-input"
              />
            </div>
          </div>
        ))}

        <button
          type="submit"
          className="external-invoice-submit-button"
          disabled={submitting}
        >
          {submitting ? "Updating..." : "Update Invoice"}
        </button>
      </form>
    </div>
  );
};

export default EditExternalInvoiceForm;
