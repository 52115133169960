import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Toast from "../../utils/toast";
import ConfirmModal from "../Members/ConfirmModal";
import "./InvoiceList.css";

const ExternalInvoicesList = () => {
  const [invoices, setInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [invoiceToDelete, setInvoiceToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const invoicesPerPage = 10;
  const [downloading, setDownloading] = useState({});

  const { id } = useParams();

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}externalinvoice`,
          { method: "GET" }
        );

        if (response.ok) {
          const data = await response.json();
          setInvoices(data.data.invoices || []);
        } else {
          const data = await response.json();
          console.error("Error:", data.message);
        }
      } catch (error) {
        console.error("Error fetching invoices:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, [id, searchTerm]);

  const indexOfLastInvoice = currentPage * invoicesPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
  const currentInvoices = invoices.slice(
    indexOfFirstInvoice,
    indexOfLastInvoice
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(invoices.length / invoicesPerPage);

  const handleDeleteClick = (id) => {
    setInvoiceToDelete(id);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}invoice/${invoiceToDelete}`,
        { method: "DELETE" }
      );

      if (response.ok) {
        setInvoices(
          invoices.filter((invoice) => invoice.id !== invoiceToDelete)
        );
        setShowModal(false);
      } else {
        console.error("Error deleting invoice:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting invoice:", error);
    }
  };

  const handleDownloadInvoice = async (invoiceId) => {
    setDownloading((prev) => ({ ...prev, [invoiceId]: true }));
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}externalinvoice/download/${invoiceId}`,
        { method: "POST" }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `invoice-${invoiceId}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        Toast.fire({ icon: "success", title: "PDF Downloaded" });
      } else {
        const errorData = await response.json();
        Toast.fire({ icon: "error", title: errorData.data });
      }
    } catch (error) {
      Toast.fire({ icon: "error", title: error.message });
    } finally {
      setDownloading((prev) => ({ ...prev, [invoiceId]: false }));
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="invoices1-container">
      <div className="invoices1-header">
        <input
          type="text"
          placeholder="Search by Invoice ID or Client Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-bar"
        />

        <Link to="/externalinvoiceform">
          <button className="invoices1-button">Create External Invoice</button>
        </Link>
      </div>

      <table className="invoices1-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Invoice Id</th>
            <th>Client Name</th>
            <th>Email</th>
            <th>Date</th>
            <th>Actions</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {currentInvoices.map((invoice, index) => (
            <tr key={invoice.id}>
              <td>{indexOfFirstInvoice + index + 1}</td>
              <td>{invoice.invoiceNo}</td>
              <td>{invoice.customerName}</td>
              <td>{invoice.customerEmail}</td>
              <td>{invoice.date}</td>
              <td className="actions">
                <Link to={`/editexternalinvoice/${invoice.id}`}>
                  <button className="action-button edit">
                    <i className="fas fa-edit"></i> Edit
                  </button>
                </Link>
                <button
                  className="action-button delete"
                  onClick={() => handleDeleteClick(invoice.id)}
                >
                  <i className="fas fa-trash-alt"></i> Delete
                </button>
                <Link to={`/tax/${invoice.id}`}>
                  <button className="action-button view">
                    <i className="fas fa-eye"></i> View
                  </button>
                </Link>
              </td>
              <td>
                <button
                  className="t-button"
                  onClick={() => handleDownloadInvoice(invoice.id)}
                  disabled={!!downloading[invoice.id]}
                >
                  {downloading[invoice.id] ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    "Download"
                  )}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
      </div>

      <ConfirmModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default ExternalInvoicesList;
