import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ConfirmModal from "../Members/ConfirmModal";
import "./polist.css";
import Toast from "../../utils/toast";

const POLIst = () => {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [poToDelete, setPoToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const purchaseOrdersPerPage = 10;
  const [sending, setSending] = useState({}); // Track sending status per payslip
  const [downloading, setDownloading] = useState({}); // Track downloading status per payslip
  

  useEffect(() => {
    const fetchPurchaseOrders = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}purchaseorder/`, {
          method: "GET",
        });

        if (response.ok) {
          const data = await response.json();
          setPurchaseOrders(data.data || []);
        } else {
          const errorData = await response.json();
          console.error("Error:", errorData.message);
          
        }
      } catch (error) {
        console.error("Error fetching purchase orders:", error);
     
      } finally {
        setLoading(false);
      }
    };

    fetchPurchaseOrders();
  }, []);

  const indexOfLastPO = currentPage * purchaseOrdersPerPage;
  const indexOfFirstPO = indexOfLastPO - purchaseOrdersPerPage;
  const currentPurchaseOrders = purchaseOrders.slice(indexOfFirstPO, indexOfLastPO);

  const filteredPurchaseOrders = currentPurchaseOrders.filter(po =>
    po.poNumber.includes(searchTerm) || po.vendorName.includes(searchTerm)
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(purchaseOrders.length / purchaseOrdersPerPage);

  const handleDeleteClick = (id) => {
    setPoToDelete(id);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}purchase-orders/${poToDelete}`, {
        method: "DELETE",
      });

      if (response.ok) {
        setPurchaseOrders(purchaseOrders.filter((po) => po.id !== poToDelete));
        setShowModal(false);
        Toast.success("Purchase Order deleted successfully");
      } else {
        console.error("Error deleting purchase order:", response.statusText);
    
      }
    } catch (error) {
      console.error("Error deleting purchase order:", error);
    
    }
  };

  const handleDownloadPo = async (posId ) => {
    setDownloading((prev) => ({ ...prev, [posId]: true })); // Set specific payslip as downloading
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}purchaseorder/download/${posId}`,
        {
          method: "POST",
        }
      );

      if (response.ok) {
        const contentDisposition = response.headers.get("Content-Disposition");
        const fileName = contentDisposition
          ? contentDisposition.split("filename=")[1].replace(/"/g, "")
          : "po.pdf";

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;

        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        Toast.fire({
          icon: "success",
          title: "PDF Downloaded",
        });
      } else {
        const errorData = await response.json();
          
      }
    } catch (error) {
     console.error("Error downloading payslip:", error);
    } finally {
      setDownloading((prev) => ({ ...prev, [posId]: false })); // Reset specific payslip downloading status
    }
  };

  const handleSend = (po) => {
    const subject = `Purchase Order ${po.poNumber}`;
    const body = `
      Dear ${po.vendorName},\n\n
      Please find the attached purchase order (PO Number: ${po.poNumber}).\n
      We appreciate your business.\n\n
      Regards,\n Labyrinth Global Solutions India Private Limited
    `;
  
    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
    // Open Outlook or default email client
    window.location.href = mailtoLink;
  
 
  };
  

  return (
    <div className="po-container">
      <div className="po-header">
        <input
          type="text"
          placeholder="Search by PO ID or Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-bar"
        />
        <Link to="/purchaseform">
          <button className="po-button">Add Purchase Order</button>
        </Link>
      </div>
      <table className="po-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Vendor Name</th>
            <th>PO Number</th>
            <th>Grand Total</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredPurchaseOrders.map((po, index) => (
            <tr key={po.id}>
              <td>{indexOfFirstPO + index + 1}</td>
              <td>{po.vendorName}</td>
              <td>{po.poNumber}</td>
              <td>{po.grandTotal}</td>
              <td className="actions">
                <Link to={`/purchase-order/${po.id}`}>
                  <button className="action-button view">
                    <i className="fas fa-eye"></i>
                  </button>
                </Link>
                <Link to={`/editpo/${po.id}`}>
                  <button className="action-button edit">
                    <i className="fas fa-edit"></i>
                  </button>
                </Link>
                <button
                  className="action-button delete"
                  onClick={() => handleDeleteClick(po.id)}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
                <button
                  className="action-button send-download"
                  onClick={() => handleDownloadPo(po.id)}
                  disabled={!!downloading[po.id]} // Disable only if specific payslip is downloading
                >
                  {downloading[po.id] ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    "Download"
                  )}
                </button>
                
                <button
  className="action-button send"
  onClick={() => handleSend(po)}
>
  Send
</button>

              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <ConfirmModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default POLIst;
