// TaxInvoice.js
import React from 'react';
import './TaxInvoices.css'; // Import CSS
import logo from "../../Images/company-logo.png";
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

const TaxInvoices = () => {


  const { id } = useParams(); // Get the ID from the route parameters
  const [invoiceData, setInvoiceData] = useState(null);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Extract only the YYYY-MM-DD part
  };
  useEffect(() => {
    fetch(`http://localhost:3009/api/v1/externalinvoice/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setInvoiceData(data.data); // Set the fetched data to state
        }
      })
      .catch((error) => console.error('Error fetching invoice data:', error));
  }, [id]);

  if (!invoiceData) {
    return <p>Loading...</p>; // Show loading message until data loads
  }


  return (
   
    <div className="invoice-container">
      {/* Header */}
      <div>
        <img src={logo} alt="Company Logo" className="company-logo" />
        <div className="invoices-right-texts">
          <p className="invoices-title">Tax Invoice</p>
        
        </div>
        <p className="invoice-originals">ORIGINAL FOR RECIPIENT</p>
      </div>

      {/* Invoice Details */}
      <div className="invoices-right-text">
        <p className="invoices-small-text">Invoice No : {invoiceData.invoiceNo}</p>
        <p className="invoices-small-text">Date :{formatDate(invoiceData.date)}</p>
      </div>

      {/* Divider Line */}
      <div className="invoices-divider"></div>

      {/* Export Warning Section */}
      <div className="invoices-content-section">
        <p>
          Without first obtaining approval from the U.S. Government or as
          otherwise authorized by U.S. law and regulations. Items labeled with
          "AL" unequal to "N" are subject to European / national export
          authorization. Items without label, with label "AL:N" / "ECCN:N" or
          label "AL:9X9999" / "ECCN:9X9999" may require authorization from
          responsible authorities, depending on the final end-use, or the
          destination.
        </p>
      </div>

      {/* Payment Details */}
      <div className="invoices-content-section">
        <p><strong>Payment terms:</strong> Prepayment, without offset or deduction</p>
        <p>Please transfer the amount due to our bank account listed below.</p>
      </div>

      {/* Table for Bank Details */}
      <table className="invoices-table">
        <thead>
          <tr>
            <th>Account number</th>
            <th>Bank</th>
            <th>IFSC No.</th>
            <th>Micr No.</th>
            <th>IBAN</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{invoiceData.accountNumber}</td>
            <td> {invoiceData.bankName}</td>
            <td> {invoiceData.ifscCode}</td>
            <td>{invoiceData.micrNum}</td>
            <td>{invoiceData.iban}</td>
          </tr>
        </tbody>
      </table>

      {/* Legal Statement */}
      <div className="invoices-content-section">
        <p>
          This document has been generated automatically by a data processing
          system and is legally binding without a signature.
        </p>
      </div>

      {/* Regards Section */}
      <div className="invoices-content-section">
        <p>With kind regards,</p>
        <p>Siemens Industry Software (India) Pvt Ltd</p>
      </div>
      <div style={{height:"60vh"}}>

      </div>

     
    </div>
   
 
  );
};

export default TaxInvoices;
